import { TranslationFunction } from 'i18next';
import { EditorSDK, EditorPlatformApp } from '@wix/platform-editor-sdk';
import { MEMBERS_AREA } from '@wix/app-definition-ids';

import { EditorReadyOptions, Experiments } from './types';
import { manifestFactory } from './manifest';
import { handleOrFallback } from './error-handlers';
import { getTranslationFunction } from './i18n';
import { shouldRegisterGfppEventListeners, registerGfppEventListeners } from './event-listeners';
import * as api from './api';

interface SantaMembersAppApi {
  refreshMembersAreaApps(): Promise<void>;
}

let appDefinitionId: string;
let editorOptions: EditorReadyOptions;
let t: TranslationFunction | null = null;
let experiments: Experiments | null = null;

const getSantaMembersApi = (editorSDK: EditorSDK) => {
  const payload = { appDefinitionId: MEMBERS_AREA };
  const santaMembersApi = editorSDK.application.getPublicAPI('', payload);

  return santaMembersApi as Promise<SantaMembersAppApi>;
};

const initTranslationsAndExperiments = (editorSDK: EditorSDK) => async () => {
  experiments = await editorOptions.essentials.createExperiments('members-area');

  await Promise.all([
    getTranslationFunction(editorSDK).then((_t) => (t = _t)),
    experiments.conduct('specs.membersArea.customProfile', 'false'),
  ]);
};

const editorReady: EditorPlatformApp['editorReady'] = async (editorSDK, appDefId, options) => {
  appDefinitionId = appDefId;
  // eslint-disable-next-line prettier/prettier
  editorOptions = options as unknown as EditorReadyOptions;

  await handleOrFallback(initTranslationsAndExperiments(editorSDK), undefined);

  if (experiments && shouldRegisterGfppEventListeners(appDefinitionId, experiments)) {
    registerGfppEventListeners(editorSDK);
  }
};

const getAppManifest: EditorPlatformApp['getAppManifest'] = () => {
  if (experiments?.enabled('specs.membersArea.customProfile') && t) {
    return handleOrFallback(() => manifestFactory(appDefinitionId, t!), {});
  }

  return {};
};

const exports_ = (editorSDK: EditorSDK) => ({
  private: {
    refreshMembersAreaApps: async () => {
      const membersEditorApi = await getSantaMembersApi(editorSDK);
      return membersEditorApi.refreshMembersAreaApps();
    },
    openMembersAccountBm: (origin: string) => {
      return editorSDK.editor.openDashboardPanel('', {
        url: `/members-account/?referralInfo=${origin}`,
        closeOtherPanels: false,
      });
    },
  },
  public: {
    refreshApp: () => api.refreshApp(editorSDK),
    setBooleanStyleParamForWidgets: (key: string, value: boolean) =>
      api.setBooleanStyleParamForWidgets(editorSDK, appDefinitionId, key, value),
  },
});

export { editorReady, getAppManifest, exports_ as exports };
